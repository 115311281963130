<script>
import Cookies from 'js-cookie'

export default {
  name: 'UserMixin',
  methods: {
    getUserData() {
      return JSON.parse(Cookies.get('CANDY_LOGISTICS_CMS_LID'))
    },
    async checkUser() {
      let resultText = '登入過期，請重新登入'
      let resultType = false
      const userData = this.getUserData()
      if (this.$validate.DataValid(userData)) {
        try {
          const response = await this.$XHR.api('get_user_by_id', { id: userData.id })
          const data = response

          if (data.username === userData.username && data.email === userData.email && data.phone === userData.phone) {
            resultType = true
          }
        } catch (error) {
          this.$func.log('---token fail---')
          this.$func.log(error)
        }
      }

      if (!resultType) {
        Cookies.remove('CANDY_LOGISTICS_CMS_LID')
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: resultText,
          type: 'error',
          refresh: false,
          redirect: JSON.stringify({name: 'login'}),
        })
        this.$store.dispatch('toggleOverlay', true)
      }

      return resultType
    },
  },
}
</script>
