<template>
  <!-- https://ithelp.ithome.com.tw/articles/10223518 -->
  <!-- $emit used when child element want to pass some data to parent element-->
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <!-- <v-img
        :src="require('@/assets/images/logos/logo.png')"
        max-height="48px"
        max-width="142px"
        alt="logo"
        contain
        class="me-3"
      ></v-img> -->

      <router-link :to="{ name: 'PickUp' }" class="d-flex align-center text-decoration-none">
        <v-slide-x-transition>
          <h2 class="app-title text--primary">C&Y Logistics</h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu pr-5">
      <nav-menu-link v-for="link in nav_link" :key="link.title" :title="link.title" :to="link.name"></nav-menu-link>
    </v-list>

    <div>
      <span class="copyright">&copy; 2024 <br />C&Y Logistics Limited. <br />All rights reserved.</span>
    </div>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    nav_link: [
      {
        title: '提單',
        name: { name: 'PickUp' },
      },
      {
        title: '備貨',
        name: { name: 'Prepare' },
      },
      {
        title: '出貨',
        name: { name: 'Export' },
      },
      {
        title: '庫存',
        name: { name: 'Inventory' },
      },
    ],
  }),
}
</script>

<style>
.v-navigation-drawer__content {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  max-height: 100%;
}
</style>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  background-color: #fff !important;
  .v-list {
    flex: 1 !important;
    .v-list-item {
      &.vertical-nav-menu-link {
        ::v-deep .v-list-item__icon {
          .v-icon {
            transition: none !important;
          }
        }
      }
    }
  }
}

.vertical-nav-menu {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.v-application.theme--dark .app-navigation-menu {
  background-color: #272727 !important;
}

.copyright {
  display: block;
  padding: 10px 6px 10px 16px;
  font-size: 0.7rem;
  line-height: 1.5;
}
</style>
