<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <!-- @click == v-on:click = onclick event -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>

          <h1 id="current-page-meta-title"></h1>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <!-- <theme-switcher></theme-switcher> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="ms-3"
                v-bind="attrs"
                v-on="on"
                color="warning"
                @click="$store.dispatch('toggleRemarkArea', true)"
              >
                <v-icon>
                  {{ icons.mdiLightbulb }}
                </v-icon>
              </v-btn>
            </template>
            <span>記事</span>
          </v-tooltip>

          <app-bar-menu></app-bar-menu>
        </div>
      </div>
    </v-app-bar>

    <!-- main content of this app -->
    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <!-- Remark Area -->
    <v-navigation-drawer
      :value="showRemarkArea"
      app
      floating
      width="300"
      right
      disable-resize-watcher
      @input="controlRemarkArea"
    >
      <div class="remark-area-header pl-4 pr-2 pt-4 pb-2">
        <h3>記事</h3>

        <v-btn icon @click="$store.dispatch('toggleRemarkArea', false)">
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <!-- initial fetching -->
      <template v-if="initialFetching">
        <div class="d-flex align-center justify-center" style="height: 100%">
          <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
        </div>
      </template>

      <!-- Add Remark -->
      <template v-else>
        <div class="my-2">
          <v-btn block text @click="addNewRemark">
            <v-icon class="mr-2" color="warning">{{ icons.mdiPlus }}</v-icon>
            新增
          </v-btn>
        </div>
        <div class="mt-2 mb-4 px-4" v-if="showAddRemark">
          <div class="d-flex align-center justify-end mb-2">
            <v-btn icon small @click="showAddRemark = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </div>
          <v-textarea solo label="新增記事" auto-grow hide-details="auto" v-model="newRemarkContent"></v-textarea>
          <v-btn small depressed color="secondary" class="mt-3" block @click="saveNewRemark">儲存</v-btn>
        </div>

        <template v-for="(edit, index) in remarkData">
          <div
            class="remark-content mb-4 mx-4 pa-2"
            @click="startEditRemark(index)"
            v-if="!edit.editRemark"
            :key="edit.id"
          >
            <div>{{ edit.content }}</div>
          </div>
          <div class="mt-2 px-4 mb-4" :key="`edit_${edit.id}`" v-else>
            <div class="d-flex align-center justify-space-between mb-2">
              <v-btn icon small @click="openDeleteDialog(edit.id)" color="error">
                <v-icon>{{ icons.mdiDelete }}</v-icon>
              </v-btn>
              <v-btn icon small @click="edit.editRemark = false">
                <v-icon>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </div>
            <v-textarea solo auto-grow hide-details="auto" v-model="editRemarkContent"></v-textarea>
            <v-btn small depressed color="secondary" class="mt-3" block @click="saveEditRemark(edit.id)">儲存</v-btn>
          </div>
        </template>

        <div class="d-flex align-center justify-center my-2" v-intersect="startPagination" v-if="!disablePagination">
          <v-progress-circular indeterminate color="primary" :width="3"></v-progress-circular>
        </div>
      </template>
    </v-navigation-drawer>

    <Dialog
      :dialog="dialogOpen"
      :text="'刪除記事'"
      :isDeleteDialog="true"
      :max_width="600"
      v-on:close="dialogCloseEvent"
    ></Dialog>
  </v-app>
</template>

<script>
import { mdiBellOutline, mdiLightbulb, mdiClose, mdiPlus, mdiDelete } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarMenu from './components/AppBarMenu.vue'
import Dialog from '@/components/Dialog.vue'

// 記事

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarMenu,
    Dialog,
  },
  data: () => ({
    remarkData: [],
    showAddRemark: false,
    isDrawerOpen: true,
    icons: {
      mdiBellOutline,
      mdiLightbulb,
      mdiClose,
      mdiPlus,
      mdiDelete,
    },
    page: 1,
    limit: 10,
    itemTotal: 0,
    disablePagination: false,
    fetching: false,
    initialFetching: false,
    newRemarkContent: '',
    editRemarkContent: '',
    dialogOpen: false,
    deleteID: -1,
  }),

  methods: {
    controlRemarkArea(e) {
      this.showAddRemark = false

      for (let i = 0; i < this.remarkData.length; i++) {
        this.remarkData[i].editRemark = false
      }

      this.$store.dispatch('toggleRemarkArea', e)
    },
    addNewRemark() {
      this.showAddRemark = true
      this.newRemarkContent = ''

      for (let i = 0; i < this.remarkData.length; i++) {
        this.remarkData[i].editRemark = false
      }
    },
    async saveNewRemark() {
      const content = this.newRemarkContent.trim()
      if (this.$validate.DataValid(content)) {
        try {
          const payload = {
            data_type: 'remark_data',
            data_status: 'active',
            status: 'active',
            public: true,
            content,
          }

          const result = await this.$XHR.api('add_remark', payload)
          this.$func.log('---add remark---')
          this.$func.log(result)

          this.remarkData = []

          this.getRemarkData(0, this.page * this.limit)

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '新增記事成功',
            type: 'success',
            refresh: false,
            redirect: '',
          })

          this.showAddRemark = false
        } catch (error) {
          this.$func.log('---add remark fail---')
          this.$func.log(error)

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '新增記事失敗',
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }
      } else {
        this.showAddRemark = false
      }
    },
    startEditRemark(index) {
      this.showAddRemark = false
      for (let i = 0; i < this.remarkData.length; i++) {
        const obj = Object.assign({}, this.remarkData[i])

        if (i === index) {
          obj.editRemark = true
          this.editRemarkContent = obj.content
        } else {
          obj.editRemark = false
        }

        this.$set(this.remarkData, i, obj)
      }
    },
    async saveEditRemark(id, isDelete = false) {
      const content = this.editRemarkContent.trim()

      const payload = {
        data_type: 'remark_data',
        id,
      }

      if (isDelete) {
        payload.data_status = 'inactive'
      } else {
        if (this.$validate.DataValid(content)) {
          payload.content = content
        } else {
          // empty remark
          // remove the remark
          isDelete = true
          payload.data_status = 'inactive'
        }
      }

      try {
        const result = await this.$XHR.api('update_remark', payload)
        this.$func.log('---update remark---')
        this.$func.log(result)

        this.remarkData = []

        this.getRemarkData(0, this.page * this.limit)

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: isDelete ? '刪除記事成功' : '修改記事成功',
          type: 'success',
          refresh: false,
          redirect: '',
        })

        this.deleteID = -1
      } catch (error) {
        this.$func.log('---update remark fail---')
        this.$func.log(error)

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: isDelete ? '刪除記事失敗' : '修改記事失敗',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    async openDeleteDialog(id) {
      this.deleteID = id
      this.dialogOpen = true
    },
    dialogCloseEvent(action) {
      if (!action) {
        this.dialogOpen = false
        this.deleteID = -1
      } else {
        this.dialogOpen = false
        this.saveEditRemark(this.deleteID, true)
      }
    },
    async getRemarkData(page, limit) {
      if (!this.fetching && !this.initialFetching) {
        this.fetching = true
        this.initialFetching = true

        try {
          const payload = {
            page,
            limit,
          }

          const result = await this.$XHR.api('cms_get_remark', payload)
          this.$func.log('-----Get remark data-----')
          this.$func.log(result)

          this.itemTotal = result.total

          for (let i = 0; i < result.data.length; i++) {
            const obj = Object.assign({}, result.data[i])
            obj.editRemark = false
            this.remarkData.push(obj)
          }

          if (this.itemTotal === this.remarkData.length) {
            this.disablePagination = true
          }
        } catch (error) {
          this.$func.log('---get remark data fail---')
          this.$func.log(error)

          this.disablePagination = true
        } finally {
          this.fetching = false
          this.initialFetching = false
        }
      }
    },
    async startPagination(entries, observer, isIntersecting) {
      if (isIntersecting && !this.disablePagination && !this.fetching && !this.initialFetching) {
        this.page++
        this.getRemarkData(this.page, this.limit)
      }
    },
  },
  computed: {
    showRemarkArea() {
      return this.$store.getters.showRemarkArea
    },
  },
  created() {
    const fetchAllow = this.checkUser()
    if (fetchAllow) {
      this.getRemarkData(this.page - 1, this.limit)
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

#current-page-meta-title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: var(--v-primary-base) !important;
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.remark-area-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ececec;
}

.remark-content {
  width: calc(100% - 32px) !important;
  border: 1px solid lightgrey;
  border-radius: 10px;

  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;

  & > div {
    overflow: hidden;
    white-space: pre;
  }

  & p {
    margin-bottom: 0 !important;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
