/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: 'login',
},
{
  path: '/login',
  name: 'login',
  component: () => import('@/views/pages/Login.vue'),
  meta: {
    layout: 'blank',
    title: '登入',
    skipAuth: true,
  },
},
{
  path: '/pick-up',
  name: 'PickUp',
  component: () => import('@/views/pages/PickUp.vue'),
  meta: {
    title: '提單',
    requireAuth: true,
  },
},
{
  path: '/pick-up/add',
  name: 'AddPickUp',
  component: () => import('@/views/pages/PickUpDetail.vue'),
  meta: {
    title: '新增提單',
    requireAuth: true,
  },
},
{
  path: '/pick-up/:id/:searchCargoID?',
  name: 'PickUpDetail',
  component: () => import('@/views/pages/PickUpDetail.vue'),
  meta: {
    title: '提單詳細',
    requireAuth: true,
  },
},

{
  path: '/prepare',
  name: 'Prepare',
  component: () => import('@/views/pages/Prepare.vue'),
  meta: {
    title: '備貨',
    requireAuth: true,
  },
},
{
  path: '/prepare/add',
  name: 'AddPrepare',
  component: () => import('@/views/pages/PrepareDetail.vue'),
  meta: {
    title: '新增備貨',
    requireAuth: true,
  },
},
{
  path: '/prepare/:id',
  name: 'PrepareDetail',
  component: () => import('@/views/pages/PrepareDetail.vue'),
  meta: {
    title: '備貨詳細',
    requireAuth: true,
  },
},

{
  path: '/export',
  name: 'Export',
  component: () => import('@/views/pages/Export.vue'),
  meta: {
    title: '出貨',
    requireAuth: true,
  },
},
{
  path: '/export/add',
  name: 'AddExport',
  component: () => import('@/views/pages/ExportDetail.vue'),
  meta: {
    title: '新增出貨',
    requireAuth: true,
  },
},
{
  path: '/export/:id',
  name: 'ExportDetail',
  component: () => import('@/views/pages/ExportDetail.vue'),
  meta: {
    title: '出貨詳細',
    requireAuth: true,
  },
},
{
  path: '/inventory',
  name: 'Inventory',
  component: () => import('@/views/pages/Inventory.vue'),
  meta: {
    title: '庫存',
    requireAuth: true,
  },
},
{
  path: '/error',
  name: 'error',
  component: () => import('@/views/Error.vue'),
  meta: {
    title: '404',
    layout: 'blank',
  },
},
{
  path: '*',
  redirect: 'error',
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// TODO
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    // login cookie check
    const loginCookie = Cookies.get('CANDY_LOGISTICS_CMS_LID')
    if (loginCookie !== undefined && loginCookie !== '') {
      next()
    } else if (to.path !== '/login') {
      next('/login')
    } else {
      next()
    }
  } else if (to.meta.skipAuth) {
    const loginCookie = Cookies.get('CANDY_LOGISTICS_CMS_LID')
    if (loginCookie !== undefined && loginCookie !== '') {
      next('/pick-up')
    } else {
      next()
    }
  } else {
    next()
  }
})

const DEFAULT_TITLE = 'C&Y Logistics'

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = (`${to.meta.title} - ` || '') + DEFAULT_TITLE
    if (to.path !== '/login' && to.path !== '/error') {
      document.querySelector('#current-page-meta-title').textContent = (to.meta.title || '')
    }
  })
})

export default router
